import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { AdSlot } from "react-dfp"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import RecipeItem from "../components/Recipe/RecipeItem"

const Page = ({ data, pageContext }) => {
  const headerImage = getImage(data.prismicRecipeCategory.data.thumbnail)

  return (
    <>
      <Seo title={data.prismicRecipeCategory.data.title.text} />

      <Layout>
        <SimpleBanner title={data.prismicRecipeCategory.data.name.text}>
          {headerImage && (
            <GatsbyImage
              className="banner__image"
              image={headerImage}
              alt={data.prismicRecipeCategory.data.name.text}
            />
          )}
        </SimpleBanner>

        <div className="section" css="margin: 30px 0">
          <div className="container container__tight">
            <AdSlot
              adUnit="kitchen_standart"
              sizes={[
                [970, 90],
                [728, 90],
                [468, 60],
                [300, 250],
                [300, 100],
                [320, 50],
                "fluid",
              ]}
              sizeMapping={[
                {
                  viewport: [0, 0],
                  sizes: [[320, 50], [300, 250], [300, 100], "fluid"],
                },
                { viewport: [468, 0], sizes: [[468, 60], "fluid"] },
                { viewport: [760, 0], sizes: [[728, 90], "fluid"] },
                { viewport: [1002, 0], sizes: [[970, 90], "fluid"] },
              ]}
            />
          </div>
        </div>

        <div className="section">
          <div className="feed">
            {data.allPrismicRecipe.nodes.map((node, index) => {
              return <RecipeItem key={index} node={node} />
            })}
          </div>
        </div>

        <div className="section" css="margin: 30px 0">
          <div className="container container__tight">
            <AdSlot
              adUnit="kitchen_standart"
              sizes={[
                [970, 90],
                [728, 90],
                [468, 60],
                [300, 250],
                [300, 100],
                [320, 50],
                "fluid",
              ]}
              sizeMapping={[
                {
                  viewport: [0, 0],
                  sizes: [[320, 50], [300, 250], [300, 100], "fluid"],
                },
                { viewport: [468, 0], sizes: [[468, 60], "fluid"] },
                { viewport: [760, 0], sizes: [[728, 90], "fluid"] },
                { viewport: [1002, 0], sizes: [[970, 90], "fluid"] },
              ]}
            />
          </div>
        </div>

        {data.prismicRecipeCategory.data.description &&
          pageContext.currentPage === 1 && (
            <div className="section">
              <div className="container container__tight">
                <div className="column">
                  <PrismicRichText
                    field={data.prismicRecipeCategory.data.description.richText}
                  />
                </div>
              </div>
            </div>
          )}

        <div className="section" css="margin: 30px 0">
          <div className="container container__tight">
            <AdSlot
              adUnit="kitchen_standart"
              sizes={[
                [970, 90],
                [728, 90],
                [468, 60],
                [300, 250],
                [300, 100],
                [320, 50],
                "fluid",
              ]}
              sizeMapping={[
                {
                  viewport: [0, 0],
                  sizes: [[320, 50], [300, 250], [300, 100], "fluid"],
                },
                { viewport: [468, 0], sizes: [[468, 60], "fluid"] },
                { viewport: [760, 0], sizes: [[728, 90], "fluid"] },
                { viewport: [1002, 0], sizes: [[970, 90], "fluid"] },
              ]}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Page

export const query = graphql`
  query ($uid: String, $limit: Int, $skip: Int) {
    prismicRecipeCategory(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        name {
          text
        }
        meta_description {
          text
        }
        description {
          richText
        }
        thumbnail {
          gatsbyImageData(width: 2560, placeholder: BLURRED)
        }
      }
    }
    allPrismicRecipe(
      filter: {
        data: {
          recipe_categories: { elemMatch: { category: { uid: { eq: $uid } } } }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        data {
          name {
            text
          }
          thumbnail {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
          }
        }
        uid
      }
    }
  }
`
